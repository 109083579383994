import './styles/main.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useMemo, useState} from "react";
import InstallPage from "./pages/InstallPage";
import MainPage from "./pages/MainPage";
import RefreshResumePage from "./pages/RefreshResumePage";
import ChatPage from "./pages/ChatPage";
import Loading from "./elements/Loading";
import HhAuthorizePage from "./pages/HhAuthorizePage";
import HhChatAuthorizePage from "./pages/HhChatAuthorizePage";

function App() {
    const [appInitialized, setAppInitialized] = useState(false)

    const BX24 = useMemo(() => {
        // Для страницы авторизации hh.ru вне битрикса
        if (
            window.location.pathname.includes('hh_authorize') ||
            window.location.pathname.includes('hh_chat_authorize')
        ){
            console.log('bx excluded')
            setAppInitialized(true)
            return
        }

        const bx = window.BX24
        bx.init(() => setAppInitialized(true));
        return bx;
    }, [])

    if (!appInitialized) {
        return <Loading isLoading={true}/>
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<MainPage BX24={BX24}/>} />
                <Route exact path="/hh_authorize" element={<HhAuthorizePage/>} />
                <Route exact path="/install" element={<InstallPage BX24={BX24}/>} />
                <Route exact path="/update_crm_item" element={<RefreshResumePage BX24={BX24}/>} />
                <Route exact path="/chat" element={<ChatPage BX24={BX24}/>} />
                <Route exact path="/hh_chat_authorize" element={<HhChatAuthorizePage/>} />
            </Routes>
        </BrowserRouter>
    )

}

export default App;
