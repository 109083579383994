import {useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import Loading from "../elements/Loading";
import InlineNotification from "../elements/InlineNotification";

export default function HhAuthorizePage() {

    // Авторизация на hh.ru (если есть code в ссылке)
    const HHRedirectURL = useMemo(() => `${window.location.origin}/hh_authorize`, [])

    const [searchParams, setSearchParams] = useSearchParams()
    const [notificationType, setNotificationType] = useState('error')
    const [notificationMessage, setNotificationMessage] = useState('')

    useEffect( () => {
        console.log('works')
        let error = searchParams.get('error') // access_denied
        let authorization_code = searchParams.get('code')
        let state = searchParams.get('state')
        searchParams.delete('code')
        searchParams.delete('state')
        searchParams.delete('error')
        setSearchParams(searchParams)

        if (error) {
            setNotificationType('error')
            setNotificationMessage(`Ошибка авторизации на hh.ru: ${error}`)
            return
        }
        if (!authorization_code) {
            return
        }

        let authData = JSON.parse(state)
        console.log('authData', authData)
        let headers = {
            ...authData,
            'accept': 'application/json'
        }
        const api = axios.create({
            baseURL: `${process.env.REACT_APP__API_URL}`,
            headers: headers
        })
        api.post('/services_interactions/hh/authorize', {
            'authorization_code': authorization_code,
            'redirect_url': HHRedirectURL
        }).then((r) => {
            setNotificationMessage('Вы успешно авторизовались на hh.ru')
            setNotificationType('success')
        }).catch((error) => {
            let errorMessage = error?.response?.data?.detail
            if (!errorMessage)
                errorMessage = 'Пожалуйста, обратитесь в ТП.'
            setNotificationMessage(`Ошибка при авторизации на hh.ru. ${errorMessage}`)
            setNotificationType('error')
        })
    }, [])

    return <div className={'center-main'}>
        <div className={'main-block'}>
            <Loading isLoading={notificationMessage === ''}/>
            <InlineNotification
                type={notificationType}
                text={notificationMessage}
                hideFlag={notificationMessage.length === 0}
            />
        </div>
    </div>
}