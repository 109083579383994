import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import getAPIClientMocked from "../hooks/apiClientMocked";
import InlineNotification from "../elements/InlineNotification";
import '../styles/pages/refresh_resume.scss'
import getAPIClient from "../hooks/apiClient";

export default function RefreshResumePage({BX24}) {

    const api = useMemo(() => getAPIClient(BX24), [])

    const [notificationText, setNotificationText] = useState('')
    const [notificationType, setNotificationType] = useState('error')

    const handleResponseError = useCallback((default_text, e) => {
        console.log('error: ', e)
        setNotificationType('error')
        if (e?.response?.data?.detail) {
            setNotificationText(e.response.data.detail)
        } else {
            setNotificationText(default_text)
        }
    }, [])

    useEffect(() => {
        api.get('/services_interactions/hh').then((r) => {
            let placement = BX24.placement.info();
            let crm_item_id = `${placement.options.ID}`
            const data = r.data
            if (!data.is_authorized) {
                setNotificationText('Вы не авторизованы в hh.ru. Сделайте это в настройках приложения')
                setNotificationType('info')
                return
            }
            api.post('/services_interactions/resume_tools/update_with_new_data', {'crm_item_id': crm_item_id}).then(r => {
                setNotificationText('Данные соискателя успешно обновлены')
                setNotificationType('success')

                BX24.closeApplication();
            }).catch(e => handleResponseError('Произошла ошибка при обновлении данных соискателя', e))
        }).catch((e) => handleResponseError('Произошла ошибка при получении данных интеграции', e))
    }, [])

    return <div className={'center-main'}>
        <div className={'main-block'}>
            <Loading isLoading={notificationText === ''}/>
            <InlineNotification
                type={notificationType}
                text={notificationText}
                hideFlag={notificationText.length === 0}
            />
        </div>
    </div>
}
