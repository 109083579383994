import s from '../styles/pages/main.module.scss'
import DefaultInput from "../elements/inputs/DefaultInput";
import BitrixUserSelector from "../elements/inputs/BitrixUserSelector";
import SubmitButton from "../elements/inputs/SubmitButton";
import InlineNotification from "../elements/InlineNotification";
import Header from "../elements/Header";
import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import getAPIClient from "../hooks/apiClient";
import OneSelectUI from "../elements/inputs/one-select";
import Popup from "../elements/popup";
import AllFieldsSelector from "../elements/fields-selector/AllFieldsSelector";
import moment from "moment";
import { ReactComponent as SettingsLogo } from '../assets/settings-logo.svg';
import { ReactComponent as WindowLogo } from "../assets/window.svg";
import { ReactComponent as HeadhunterIcon } from "../assets/hh.svg";

import {ReactComponent as CopySVG} from '../assets/copy.svg';
import {useSearchParams} from "react-router-dom";


export default function MainPage({BX24}) {
    const [isLoading, setIsLoading] = useState(true)

    // Флаги, которые говорят что юзер что-то поменял после последнего получения данных
    const [spUpdatedFlag, setSpUpdatedFlag] = useState(false)
    const [mappingUpdatedFlag, setMappingUpdatedFlag] = useState(false)

    const [fieldsPopupSelectorOpenedFlag, setFieldsPopupSelectorOpenedFlag] = useState(false)

    const [notificationMessage, setNotificationMessage] = useState('')
    const [notificationType, setNotificationType] = useState('error')

    const api = useMemo(() => getAPIClient(BX24), [])

    // Смарт процессы
    const [smartProcessesList, setSmartProcessesList] = useState([])
    useEffect(() => {
        api.get('/setup/smart_processes_from_portal').then(response => {
            let sp_list = []
            for (let i in response.data) {
                let sp = response.data[i]
                sp.label = sp.title
                sp.value = sp.local_smart_process_id
                sp_list.push(sp)
            }
            setSmartProcessesList(sp_list)
        }).finally(setIsLoading(false))
    }, [api])

    const [selectedSmartProcess, setSelectedSmartProcess] = useState(undefined)

    // Список отношений (данные -> поле)
    const [dataToFieldMapping, setDataToFieldMapping] = useState([])
    useEffect(() => {
        if (selectedSmartProcess === undefined) return
        setIsLoading(true)
        api.get(
            `/portal_fields_mappings?local_b24_smart_process_id=${selectedSmartProcess.local_smart_process_id}`
        ).then(response => {
            let mapping_list = []
            for (let i in response.data) {
                let mapping_item = response.data[i]
                mapping_item.label = mapping_item.ui_name
                mapping_item.value = mapping_item.system_name
                mapping_list.push(mapping_item)
            }
            setDataToFieldMapping(mapping_list)
        }).finally(() => setIsLoading(false))
    }, [selectedSmartProcess])

    // Список доступных полей на портале
    const [availableFieldsInSmartProcess, setAvailableFieldsInSmartProcess] = useState([])
    useEffect(() => {
        if (selectedSmartProcess === undefined) return
        api.get(
            `/setup/fields_smart_process?local_b24_smart_process_id=${selectedSmartProcess.local_smart_process_id}`
        ).then(response => {
            let fields_list = []
            for (let i in response.data) {
                let field = response.data[i]
                field.label = field.field_name
                field.value = field.b24_system_name
                fields_list.push(field)
            }
            setAvailableFieldsInSmartProcess(fields_list)
        })
    }, [selectedSmartProcess])

    // Если где-то в маппинге указано б24 поле, которого не существует в списке полей с бэка,
    // то удаляем это поле в маппинге
    const [wrongMappingClearedFlag, setWrongMappingClearedFlag] = useState(false)

    useEffect(() => {
        console.log('called', wrongMappingClearedFlag, dataToFieldMapping, availableFieldsInSmartProcess)

        if (dataToFieldMapping.length === 0 || availableFieldsInSmartProcess.length === 0) {
            return
        }
        if (wrongMappingClearedFlag) {
            return
        }
        setWrongMappingClearedFlag(true)

        let copiedDataToFieldMapping = JSON.parse(JSON.stringify(dataToFieldMapping))
        for (let i in copiedDataToFieldMapping) {
            let selectedField = availableFieldsInSmartProcess.filter(
                (field) => copiedDataToFieldMapping[i].b24_system_name === field.b24_system_name
            )[0]

            if (selectedField === undefined) {
                copiedDataToFieldMapping[i]['b24_system_name'] = null
            }
        }
        setDataToFieldMapping(copiedDataToFieldMapping)
    }, [dataToFieldMapping, availableFieldsInSmartProcess])


    // Счетчик для отображения на кнопке
    const currentConfiguredFieldsCounter = useMemo(() => {
        let counter = 0;
        for (let i in dataToFieldMapping) {
            if (dataToFieldMapping[i].b24_system_name !== null) {
                counter += 1
            }
        }
        return counter
    }, [dataToFieldMapping])


    const [globalNotificationMessage, setGlobalNotificationMessage] = useState('')
    const [globalNotificationType, setGlobalNotificationType] = useState('error')

    // Получение конфига, установка текущего СП
    useEffect(() => {
        if (smartProcessesList === undefined || smartProcessesList.length === 0)
            return

        api.get(`/bitrix_portals/settings`).then(response => {
            let currentSP = JSON.parse(JSON.stringify(smartProcessesList.filter(
                (el) => response.data.local_smart_process_id === el.local_smart_process_id
            )[0]))
            setSelectedSmartProcess(currentSP)
        })
    }, [smartProcessesList])

    // Обновление конфигурации приложения (смарт-процесс, маппинги)
    const saveChanges = useCallback(async () => {
        let errorText = ''
        setNotificationMessage('')

        // отправка запрос на обновление settings
        if (spUpdatedFlag) {
            try {
                await api.post(`/bitrix_portals/settings`, selectedSmartProcess)
                setSpUpdatedFlag(false)
            } catch (e) {
                errorText = 'Ошибка при обновлении выбранного СП; '
            }
        }

        // Отправляем запрос на обновление mapping
        if (mappingUpdatedFlag) {
            try {
                await api.put(`/portal_fields_mappings`, dataToFieldMapping)
                setMappingUpdatedFlag(false)
            } catch (e) {
                errorText = errorText + 'Ошибка при обновлении маппинга данных к полям'
            }
        }

        // Дорабатываем сообщение с ошибкой, если таковые имеются
        if (errorText.length !== 0) {
            errorText += '. Мы уже получили информацию об ошибках и исправим их в течение 1 рабочего дня. Так же дополнительно вы можете написать в тех. поддержку, нажав на кнопку "Чат поддержки"'
            setNotificationType('error')
            setNotificationMessage(errorText)
        } else {
            setNotificationType('success')
            setNotificationMessage('Данные успешно обновлены')
        }
    }, [selectedSmartProcess, dataToFieldMapping, spUpdatedFlag, mappingUpdatedFlag])

    // ------ Работа с токенами ------

    const [tokens, setTokens] = useState([])

    // --- Взаимодействие с апи

    // Функция для получения списка токенов
    const apiFetchTokens = useCallback(async () => {
        let response = await api.get(`/extension_tokens`)
        return response.data
    }, [api])

    // Функция для удаления токена
    const apiDeleteToken = useCallback(async (token_id) => {
        await api.delete(`/extension_tokens/${token_id}`)
    }, [api])

    // Функция для создания токена
    const apiCreateToken = useCallback(async (token_name) => {
        let data = {
            token_name: token_name
        }
        let response = await api.post(`/extension_tokens`, data)
        return response.data
    }, [api])

    // --- Функции для всего процесса получения, изменения, удаления токенов

    // Первичное получение всех токенов
    useEffect(() => {
        apiFetchTokens().then(fetchedTokens => setTokens(fetchedTokens.items))
    }, [apiFetchTokens])

    // Удаление токена
    const deleteToken = useCallback(async (token_id) => {
        await apiDeleteToken(token_id)
        let fetchedTokens = await apiFetchTokens()
        setTokens(fetchedTokens.items)
    }, [apiDeleteToken, apiFetchTokens, setTokens])

    // Создание токена
    const [newTokenName, setNewTokenName] = useState('')
    const createToken = useCallback(async () => {
        setNewTokenName('')
        await apiCreateToken(newTokenName)
        let fetchedTokens = await apiFetchTokens()
        setTokens(fetchedTokens.items)
    }, [apiCreateToken, newTokenName])

    // Автоматизация hh
    const [searchParams, setSearchParams] = useSearchParams()

    const [HHClientID, setHHClientID] = useState(undefined)
    const HHRedirectURL = useMemo(() => `${window.location.origin}/hh_authorize`, [])
    const [isHHAuthorized, setIsHHAuthorized] = useState(false)
    const [hhNotificationType, setHhNotificationType] = useState('error')
    const [hhNotificationMessage, setHhNotificationMessage] = useState('')
    const [allDirections, setAllDirections] = useState(undefined)
    const [HHsettingsUpdatedFlag, setHHsettingsUpdatedFlag] = useState(false)
    const [responsesSelectedDirection, setResponsesSelectedDirection] = useState(undefined)
    const [responsesSelectedAssignedById, setResponsesSelectedAssignedById] = useState(undefined)
    const [responsesSelectedAssignedByName, setResponsesSelectedAssignedByName] = useState(undefined)

    useEffect(() => {
        api.get('/services_interactions/hh/app_authorization_data').then((r) => {
            setHHClientID(r.data.hh_client_id)
        }).catch((e) => {
            setHhNotificationMessage('Произошла ошибка при попытке получить данные для авторизации. Пожалуйста, напишите в ТП')
            setNotificationType('error')
        })
    }, [])

    // Получение направлений (категорий) смарт процесса
    useEffect(() => {
        if (!selectedSmartProcess) {
            return
        }
        api.get(
            `/setup/categories_smart_process?local_b24_smart_process_id=${selectedSmartProcess.local_smart_process_id}&b24_smart_process_id=${selectedSmartProcess.smart_process_id}`
        ).then((r) => {
            let category_list = []
            for (let i in r.data) {
                let category = r.data[i]
                category['value'] = category['direction_id']
                category['label'] = category['direction_name']
                category_list.push(category)
            }
            setAllDirections(category_list)
        }).catch((e) => {
            let errorMessage = e?.response?.data?.detail
            if (!errorMessage)
                errorMessage = 'Пожалуйста, обратитесь в ТП.'
            setHhNotificationMessage(`Произошла ошибка при получении списка направлений смарт процесса. ${errorMessage}`)
            setHhNotificationType('error')
        })
    }, [selectedSmartProcess])

    // Получение текущих данных о настройках автоматизации hh.ru
    const getHhInteractionData = useCallback(() => {
        if (!allDirections || allDirections.length === 0) {
            return
        }
        api.get('/services_interactions/hh').then((r) => {
            const data = r.data
            setIsHHAuthorized(data.is_authorized)
            if (!data.is_authorized && data.access_token !== null) {
                setHhNotificationType('error')
                setHhNotificationMessage('Произошла ошибка - слетела авторизация hh.ru. Авторизуйтесь пожалуйста снова')
            }
            setResponsesSelectedAssignedById(data.responses_b24_assigned_by_id)
            setResponsesSelectedAssignedByName(data.responses_b24_assigned_by_name)
            if (data.responses_b24_category_id !== null) {
                let found_category;
                for (let i in allDirections) {
                    let direction = allDirections[i]
                    if (`${direction.direction_id}` === `${data.responses_b24_category_id}`) {
                        found_category = direction
                        break
                    }
                }
                setResponsesSelectedDirection(found_category)
            }
        }).catch((error) => {
            let errorMessage = error?.response?.data?.detail
            if (!errorMessage)
                errorMessage = 'Пожалуйста, обратитесь в ТП.'
            setHhNotificationMessage(`Произошла ошибка при получении данных интеграции с hh.ru. ${errorMessage}`)
            setHhNotificationType('error')
        })
    }, [allDirections])


    useEffect(() => {
        if (isHHAuthorized)
            return

        getHhInteractionData()
        const id = setInterval(() => {
            getHhInteractionData()
        }, 3000);
        return () => clearInterval(id);
    }, [getHhInteractionData, isHHAuthorized])


    // Деавторизация на hh.ru
    const deauthorizeHH = useCallback(() => {
        api.post('/services_interactions/hh/unauthorize').then((r) => {
            setHhNotificationMessage('Вы деавторизовались на hh.ru')
            setHhNotificationType('success')
            setIsHHAuthorized(false)
        }).catch((error) => {
            let errorMessage = error?.response?.data?.detail
            if (!errorMessage)
                errorMessage = 'Пожалуйста, обратитесь в ТП.'
            setHhNotificationMessage(`Ошибка при деавторизации на hh.ru. ${errorMessage}`)
            setHhNotificationType('error')
        })
    }, [])


    const postNewHHSettings = useCallback(() => {
        const data = {
            hh_auto_add_responses_flag: !!responsesSelectedDirection,
            responses_b24_assigned_by_id: !!responsesSelectedAssignedById ? `${responsesSelectedAssignedById}` : null,
            responses_b24_assigned_by_name: !!responsesSelectedAssignedByName ? `${responsesSelectedAssignedByName}`: null,
            responses_b24_category_id: !!responsesSelectedDirection ? `${responsesSelectedDirection.direction_id}`: null
        }
        console.log('data', data)
        api.put('/services_interactions/hh', data).then(() => {
            setHHsettingsUpdatedFlag(false)
            setHhNotificationType('success')
            setHhNotificationMessage('Данные интеграции hh.ru успешно обновлены')
        }).catch((e) => {
            let errorMessage = e?.response?.data?.detail
            if (!errorMessage)
                errorMessage = 'Пожалуйста, обратитесь в ТП.'
            setHhNotificationType('error')
            setHhNotificationMessage(`Ошибка при обновлении данных интеграции hh.ru. ${errorMessage}`)
        })
    }, [responsesSelectedDirection, responsesSelectedAssignedById, responsesSelectedAssignedById])

    const [selectedMenuItem, setSelectedMenuItem] = useState('settings') // settings, extension, hh

    return (
        <>
            <Loading isLoading={isLoading}/>
            <Header/>
            <div className={s.main}>
                <InlineNotification
                    type={globalNotificationType}
                    text={globalNotificationMessage}
                    hideFlag={globalNotificationMessage.length === 0}
                />
                <div className={s.main_container}>
                    <div className={s.left_side_menu}>
                        <div className={s.menu_list}>
                            <div
                                className={s.menu_item + ' ' + (selectedMenuItem === 'settings' ? s.active_menu_item : '')}
                                onClick={() => setSelectedMenuItem('settings')}
                            >
                                <div className={s.menu_item_icon}><SettingsLogo/></div>
                                <div className={s.menu_item_text}>Общие настройки</div>
                            </div>
                            <div className={s.menu_item + ' ' + (selectedMenuItem === 'extension' ? s.active_menu_item : '')}
                                 onClick={() => setSelectedMenuItem('extension')}
                            >
                                <div className={s.menu_item_icon}><WindowLogo/></div>
                                <div className={s.menu_item_text}>Работа с расширением</div>
                            </div>
                            <div className={s.menu_item+ ' ' + (selectedMenuItem === 'hh' ? s.active_menu_item : '')}
                                 onClick={() => setSelectedMenuItem('hh')}
                            >
                                <div className={s.menu_item_icon}><HeadhunterIcon/></div>
                                <div className={s.menu_item_text}>Автоматизация hh.ru</div>
                            </div>
                        </div>
                    </div>
                    <div className={s.active_block}>
                        {(selectedMenuItem === 'settings') && <div className={s.settings}>
                            <div className={s.settings_item}>
                                <OneSelectUI
                                    title={'Выберите, куда сохранять резюме'}
                                    selectionOptions={smartProcessesList}
                                    selectedOption={selectedSmartProcess}
                                    setSelectedOption={(selected) => {
                                        setSelectedSmartProcess(selected)
                                        setSpUpdatedFlag(true)
                                    }}
                                    placeholder={'Выберите, куда будут попадать резюме'}
                                />
                            </div>
                            <div className={s.settings_item}>
                                <div className={'title'}>
                                    Выберите поля в этой сущности, в которые будут заноситься данные
                                </div>
                                <button className={'fields-selector-popup-button'} onClick={
                                    () => {
                                        setFieldsPopupSelectorOpenedFlag(true)
                                        setMappingUpdatedFlag(true)
                                    }
                                }>
                                    {currentConfiguredFieldsCounter === 0
                                        ? 'Настроить сохраняемые данные'
                                        : `${currentConfiguredFieldsCounter}/${dataToFieldMapping.length} Соотнесено данных`
                                    }
                                </button>
                                <Popup openedFlag={fieldsPopupSelectorOpenedFlag}
                                       setOpenedFlag={setFieldsPopupSelectorOpenedFlag}>
                                    {fieldsPopupSelectorOpenedFlag && <AllFieldsSelector
                                        dataToFieldMapping={dataToFieldMapping}
                                        setDataToFieldMapping={setDataToFieldMapping}
                                        availableFieldsInSmartProcess={availableFieldsInSmartProcess}
                                        comment={'Вы сможете настроить/изменить эти данные позже'}
                                    />}
                                </Popup>
                            </div>
                            <SubmitButton
                                onClick={saveChanges}
                                disabled={!mappingUpdatedFlag && !spUpdatedFlag}
                                className={s.submit}
                                text={'Сохранить'}
                            />
                            <InlineNotification
                                type={notificationType}
                                text={notificationMessage}
                                hideFlag={notificationMessage.length === 0}
                            />
                        </div>}
                        {(selectedMenuItem === 'extension') && <div className={s.extension_settings}>
                            <div className={s.download_extension_row}>
                                <div className={s.extension_description}>
                                    Расширение даёт возможность автоматически заносить резюме в CRM, когда вы находитесь на странице соискателя hh.ru
                                </div>
                                <button
                                    className={s.download_extension_button}
                                    onClick={() => window.open(
                                        'https://chromewebstore.google.com/detail/resumescan/cadadmdnamampppdldkkodghcjdfgihf',
                                        '_blank'
                                    )}
                                >
                                    Скачать расширение
                                </button>
                            </div>
                            <div className={s.tokens_editor}>
                                <div className={s.extension_header}>
                                    Список лицензионных ключей расширения
                                </div>
                                <div className={s.tokens_popup_add_row}>
                                    <DefaultInput
                                        className={s.tokens_popup_add_row_input}
                                        title={'Введите название для нового токена'}
                                        value={newTokenName}
                                        onChangeFunction={(e) => setNewTokenName(e.target.value)}
                                    />
                                    <SubmitButton
                                        onClick={createToken}
                                        text={'+'}
                                    />
                                </div>
                                <table className={s.tokens_list}>
                                    <thead className={'tokens-list-header'}>
                                    <tr>
                                        <th>Название</th>
                                        <th>Лицензионный ключ</th>
                                        <th>Дата создания</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tokens.map(token => <tr className={'token'}>
                                        <td>{token.token_name}</td>
                                        <td>{token.access_token}</td>
                                        <td>{moment.utc(token.created_at).local().format('DD.MM.YYYY HH:mm')}</td>
                                        <td className={s.token_delete} onClick={() => deleteToken(token.id)}>✖</td>
                                    </tr>)}

                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {(selectedMenuItem === 'hh') && <div className={s.hh_automatization}>
                            {!isHHAuthorized && <>
                                <div className={'title'}>
                                    Авторизуйтесь на hh.ru с аккаунта главного менеджера для настройки автоматического занесения откликов и других
                                    автоматизаций
                                </div>
                                <button
                                    className={s.hh_authorize_button}
                                    onClick={() => {
                                        const bx24Params = BX24.getAllParams()
                                        let data = {
                                            "X-ACCESS-TOKEN": bx24Params.AUTH_ID,
                                            "X-ACCESS-TOKEN-EXPIRES": bx24Params.AUTH_EXPIRES,
                                            "X-REFRESH-TOKEN": bx24Params.REFRESH_ID,
                                            "X-PORTAL-MEMBER-ID": bx24Params.MEMBER_ID,
                                            "X-PORTAL-DOMAIN": bx24Params.DOMAIN,
                                        }
                                        window.open(
                                            'https://hh.ru/oauth/authorize?' +
                                            `response_type=code&` +
                                            `client_id=${HHClientID}&` +
                                            `redirect_uri=${HHRedirectURL}&` +
                                            `state=${JSON.stringify(data)}`,
                                            '_blank',
                                            'popup'
                                        )
                                    }}
                                >
                                    АВТОРИЗОВАТЬСЯ НА HH.RU
                                </button>
                                <InlineNotification
                                    type={hhNotificationType}
                                    text={hhNotificationMessage}
                                    hideFlag={hhNotificationMessage.length === 0}
                                />
                            </>}
                            {isHHAuthorized && <>
                                <div className={'title'}>
                                    Настройте автоматический сбор откликов
                                </div>
                                <OneSelectUI
                                    title={'Выберите направление для сохранения откликов. Если не выбрано - отклики не собираются'}
                                    isClearable={true}
                                    placeholder={'Направление смарт-процесса'}
                                    selectionOptions={allDirections}
                                    selectedOption={responsesSelectedDirection}
                                    setSelectedOption={(selectedOption) => {
                                        setHHsettingsUpdatedFlag(true)
                                        setResponsesSelectedDirection(selectedOption)
                                    }}
                                />
                                <BitrixUserSelector
                                    title={'Выберите отвественного за новые отклики'}
                                    selectedUser={{
                                        name: responsesSelectedAssignedByName,
                                        id: responsesSelectedAssignedById
                                    }}
                                    setSelectedUser={(data) => {
                                        setResponsesSelectedAssignedByName(data.name)
                                        setResponsesSelectedAssignedById(data.id)
                                        setHHsettingsUpdatedFlag(true)
                                    }}
                                    BX24={BX24}
                                />
                                <SubmitButton
                                    className={s.save_hh_settings}
                                    text={'Сохранить'}
                                    disabled={!HHsettingsUpdatedFlag}
                                    onClick={postNewHHSettings}
                                />
                                <a className={s.deauth_link} onClick={deauthorizeHH} href={'#'}>Деавторизоваться</a>
                                <InlineNotification
                                    type={hhNotificationType}
                                    text={hhNotificationMessage}
                                    hideFlag={hhNotificationMessage.length === 0}
                                />
                            </>}
                        </div>
                    }
                    </div>
                </div>
            </div>
        </>
    )
}