import '../styles/pages/chat.scss'
import { ReactComponent as DoubleTickIndicator } from '../assets/double_tick_indicator.svg';
import { ReactComponent as ClockSVG } from "../assets/clock.svg";
import { ReactComponent as AirplaneIcon } from '../assets/airplane.svg';
import { ReactComponent as InfoIcon } from "../assets/info.svg";
import { ReactComponent as DocumentIcon } from "../assets/document.svg";
import { ReactComponent as PortfolioIcon } from "../assets/portfolio.svg";
import { ReactComponent as LocationIcon} from "../assets/location.svg";
import { ReactComponent as ReloadIcon } from "../assets/reload.svg";
import { ReactComponent as SwapIcon } from "../assets/swap.svg";
import { ReactComponent as HeadhunterIcon } from "../assets/hh.svg";



import {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import getWSClient from "../hooks/websocketClient";
import Loading from "../elements/Loading";
import moment from "moment";
import {useSearchParams} from "react-router-dom";
import Popup from "../elements/popup";
import InlineNotification from "../elements/InlineNotification";
import getAPIClient from "../hooks/apiClient";

function getCaseOfYear(number) {
    console.log('math', number, number % 100)
    if (5 <= (number % 100) && (number % 100) <= 20)
        return 'лет'
    else if (number % 10 === 1)
        return 'год'
    else if ([2, 3, 4].includes(number % 10))
        return 'года'
    else
        return 'лет'
}

function getCaseOfMonth(number) {
    if (5 <= (number % 100) && (number % 100) <= 20)
        return 'месяцев'
    else if (number % 10 === 1)
        return 'месяц'
    else if ([2, 3, 4].includes(number % 10))
        return 'месяца'
    else
        return 'месяцев'
}

export default function ChatPage({BX24}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [globalNotificationMessage, setGlobalNotificationMessage] = useState('')
    const [globalNotificationType, setGlobalNotificationType] = useState('error')
    const [sendMessageErrorNotificationMessage, setSendMessageErrorNotificationMessage] = useState('')
    const [updateChatErrorNotificationMessage, setUpdateChatErrorNotificationMessage] = useState('')


    const [scrollChatFlag, setScrollChatFlag] = useState(false)
    useEffect(() => {
        lastChatElement.current.scrollIntoView(true)
    }, [scrollChatFlag])

    const api = useMemo(() => getAPIClient(BX24), [])

    // Получаем hh_client_id для авторизации
    const HHRedirectURL = useMemo(() => `${window.location.origin}/hh_chat_authorize`, [])
    const [HHClientID, setHHClientID] = useState(undefined)
    useEffect(() => {
        api.get('/services_interactions/hh/app_authorization_data').then((r) => {
            setHHClientID(r.data.hh_client_id)
        }).catch((e) => {
            setGlobalNotificationMessage('Произошла ошибка при попытке получить данные для авторизации. Пожалуйста, напишите в ТП')
            setGlobalNotificationType('error')
        })
    }, [])

    // Получаем CODE для последующей отправки вебсокету
    const [authorizationCode, setAuthorizationCode] = useState()
    useEffect(() => {
        function receiveMessage(event) {
            if (event.origin !== window.location.origin) {
                console.warn(`Message received by ${event.origin}; IGNORED.`);
                return;
            }
            setAuthorizationCode(event.data);
        }
        window.addEventListener("message", receiveMessage, false);
    }, [])

    const auth = useCallback(() => {
        if (!authorizationCode) {
            window.open(
                'https://hh.ru/oauth/authorize?\n' +
                `response_type=code&\n` +
                `client_id=${HHClientID}&\n` +
                `redirect_uri=${HHRedirectURL}\n`,
                'hh_chat_auth',
                'popup'
            )
        }
    }, [HHClientID, HHRedirectURL, authorizationCode])

    useEffect(() => {
        if (!HHClientID) {
            return
        }
        auth()
    }, [HHClientID])

    // Получение ID элемента Bitrix24
    const B24ElementID = useMemo(() => {
        let placement = BX24.placement.info();
        return `${placement.options.ID}`
    }, [])

    // ------------- Работа с вебсокетом ---------------
    const wsClient = useMemo(() => getWSClient('/services_interactions/hh/chat/ws'), [])

    const [WSReadyFlag, setWSReadyFlag] = useState(false)
    useEffect(() => {
        wsClient.onopen = (e) => {
            setWSReadyFlag(true)
        }
    }, [])

    // Отправка авторизации Bitrix24 по вебсокету
    const [Bitrix24AuthorizedFlag, setBitrix24AuthorizedFlag] = useState(false)
    useEffect(() => {
        if (!WSReadyFlag) {
            return
        }
        const bx24Params = BX24.getAllParams()
        wsClient.send(
            `bitrix24_auth ${JSON.stringify({
                "X-ACCESS-TOKEN": bx24Params.AUTH_ID,
                "X-ACCESS-TOKEN-EXPIRES": bx24Params.AUTH_EXPIRES,
                "X-REFRESH-TOKEN": bx24Params.REFRESH_ID,
                "X-PORTAL-MEMBER-ID": bx24Params.MEMBER_ID,
                "X-PORTAL-DOMAIN": bx24Params.DOMAIN
            })}`
        )
    }, [WSReadyFlag])

    // Отправка авторизации hh.ru по вебсокету
    const [HHAuthorizedFlag, setHHAuthorizedFlag] = useState(false)
    useEffect(() => {
        if (!Bitrix24AuthorizedFlag || !WSReadyFlag || !authorizationCode) {
            return
        }
        let data = {
            authorization_code: authorizationCode,
            redirect_url: HHRedirectURL
        }
        wsClient.send(`hh_authorization_code ${JSON.stringify(data)}`)
    }, [Bitrix24AuthorizedFlag, WSReadyFlag, authorizationCode])

    // Отправка ID элемента и получение резюме
    const [resume, setResume] = useState()
    useEffect(() => {
        if (!HHAuthorizedFlag || !B24ElementID) {
            return
        }
        wsClient.send(`b24_element_id ${B24ElementID}`)
    }, [HHAuthorizedFlag, B24ElementID])



    // Получение списка доступных вакансий
    const [availableVacancies, setAvailableVacancies] = useState()
    useEffect(() => {
        if (!HHAuthorizedFlag || !resume) {
            return
        }
        wsClient.send(`get_available_vacancies`)
    }, [HHAuthorizedFlag, resume])
    const changeVacancy = useCallback(() => {
        wsClient.send(`get_available_vacancies`)
        setIsLoading(true)
    }, [])

    // Выбор вакансии
    const [selectedVacancy, setSelectedVacancy] = useState()
    const selectVacancy = useCallback((vacancy_id) => {
        setIsLoading(true)
        setNegotiationFoundFlag(undefined)
        setSelectedVacancy(undefined)
        wsClient.send(`select_vacancy ${vacancy_id}`)
    }, [])

    // Проверка на то что приглашение/отклик есть + отправка приглашения
    const [negotiationFoundFlag, setNegotiationFoundFlag] = useState()
    const sendNegotiation = useCallback(() => {
        if (!selectedVacancy) return
        if (selectedVacancy.negotiations_actions.length !== 0) {
            //setIsLoading(true)
            wsClient.send(`send_negotiation`)
        }
    }, [selectedVacancy])

    useEffect(() => {
        if (negotiationFoundFlag === true) {
            setIsLoading(true)
            wsClient.send(`get_chat full`)
        }
    }, [negotiationFoundFlag])

    // Чат, автообновление, отправка сообщений
    const [chatMessages, setChatMessages] = useState([])
    useEffect(() => {
        if (!negotiationFoundFlag) {
            return
        }
        const id = setInterval(() => {
            wsClient.send(`get_chat last`)
        }, 3000);
        return () => clearInterval(id);
    }, [negotiationFoundFlag])

    const handleMessagesUpdate = useCallback((messages) => {
        setUpdateChatErrorNotificationMessage('')
        let copiedChatMessages = JSON.parse(JSON.stringify(chatMessages))
        let currentChatIDs = copiedChatMessages.map((m) => m.id)
        let newMessagesFoundFlag = false
        let newMessageFromApplicantFoundFlag = false
        for (let i in messages) {
            let message = messages[i]
            // Если сообщение новое - добавляем
            if (!currentChatIDs.includes(message.id)) {
                copiedChatMessages.push(message)
                newMessagesFoundFlag = true
                if (message.author.participant_type === 'applicant') {
                    newMessageFromApplicantFoundFlag = true
                }
            } else {
                // Если сообщение уже было ранее - обновляем
                for (let j in copiedChatMessages) {
                    let copiedMessage = copiedChatMessages[j]
                    if (message.id === copiedMessage.id) {
                        console.log(message, copiedMessage)
                        copiedChatMessages[j] = message
                    }
                }
            }
        }
        // Если появились новые сообщения - убираем отображение pending сообщений
        let updatedChatMessages = []
        for (let i in copiedChatMessages) {
            let message = copiedChatMessages[i]
            if (newMessagesFoundFlag && message.is_pending) {}
            else {
                updatedChatMessages.push(message)
            }
        }
        updatedChatMessages = updatedChatMessages.sort((a, b) => a - b)
        setChatMessages(updatedChatMessages)
        if (newMessageFromApplicantFoundFlag) {
            setScrollChatFlag(prev => !prev)
        }
    }, [chatMessages, scrollChatFlag])

    const [newMessageValue, setNewMessageValue] = useState("");
    const sendChatMessage = useCallback(() => {
        if (newMessageValue.trim()) {
            setSendMessageErrorNotificationMessage('')
            wsClient.send(`send_message ${JSON.stringify(newMessageValue)}`)
            setChatMessages(prevState => [...prevState, {
                "id": prevState[prevState.length - 1]["id"] - 1 + 2,
                "text": newMessageValue,
                "state": {
                    "id": "text",
                    "name": "Текст"
                },
                "author": {
                    "participant_type": "employer"
                },
                "is_pending": true
            }])
            setNewMessageValue('')
            setScrollChatFlag(prev => !prev)
        }
    }, [chatMessages, newMessageValue, setScrollChatFlag])

    // Обработчики сообщений
    useEffect(() => {
        wsClient.onmessage = (message) => {
            const data = message.data
            const key = data.split(' ')[0]
            const value = data.split(' ').slice(1, data.split(' ').length).join(' ')
            console.log(value)
            if (key === 'global_error') {
                setIsLoading(false)
                setGlobalNotificationType('error')
                setGlobalNotificationMessage(value)
            }
            if (key === 'send_message_error') {
                setSendMessageErrorNotificationMessage(`${value} [${moment.now().format('DD.MM HH:mm')}]`)
                setScrollChatFlag(prev => !prev)
            }
            if (key === 'get_chat_error') {
                setUpdateChatErrorNotificationMessage(`${value} [${moment.now().format('DD.MM HH:mm')}]`)
                setScrollChatFlag(prev => !prev)
            }
            else if (key === 'bitrix24_authorized') {
                setBitrix24AuthorizedFlag(true)
                setIsLoading(false)
            }
            else if (key === 'hh_authorized') {
                setIsLoading(true)
                setHHAuthorizedFlag(true)
            }
            else if (key === 'resume')
                setResume(JSON.parse(value))
            else if (key === 'available_vacancies') {
                setAvailableVacancies(JSON.parse(value))
                setPopupVacanciesSelectorOpenedFlag(true)
                setIsLoading(false)
            }
            else if (key === 'selected_vacancy_accepted') {
                setChatMessages([])
                setSelectedVacancy(JSON.parse(value))
                setPopupVacanciesSelectorOpenedFlag(false)
            }
            else if (key === 'negotiation'){
                setNegotiationFoundFlag(value === 'found')
                setIsLoading(false)
            }
            else if (key === 'chat_full') {
                setChatMessages(JSON.parse(value))
                setIsLoading(false)
                setScrollChatFlag(prev => !prev)
            }
            else if (key === 'chat_last') {
                handleMessagesUpdate(JSON.parse(value))
            }
        }
    }, [handleMessagesUpdate])
    useEffect(() => {
        wsClient.onclose = () => {
            setGlobalNotificationType('error')
            setGlobalNotificationMessage('Соединение с сервером было прервано. Перезагрузите страницу по кнопке. Если такое повторяется часто - обратитесь в тех поддержку')
        }
    }, [])


    const resumeInfoList = useMemo(() => {
        // Тип отклика
        let negotiationType = 'Отклик или приглашение не найдены'
        if (negotiationFoundFlag) {
            negotiationType = selectedVacancy.negotiations_state.name
        }
        // Лет, месяцев опыта
        let experience;
        if (resume) {
            let months = resume.total_experience.months
            experience = `${months % 12} ${getCaseOfMonth(months % 12)}`
            let years = Math.floor(months / 12)
            if (years >= 1)
                experience = `${years} ${getCaseOfYear(years)} ${experience}`
        }

        return [{
            icon: <DocumentIcon/>,
            name: 'Вакансия',
            value: `${selectedVacancy?.name ? selectedVacancy.name : 'Вакансия не выбрана'}`
        },{
            icon: <InfoIcon/>,
            name: 'Отклик или приглашение',
            value: negotiationType
        },{
            icon: <PortfolioIcon/>,
            name: 'Опыт работы',
            value: resume ? experience : 'Неизвестно'
        },{
            icon: <LocationIcon/>,
            name: 'Локация соискателя',
            value: resume?.area?.name ? resume.area.name : 'Не указано'
        }]
    }, [negotiationFoundFlag, selectedVacancy, resume])

    const MIN_TEXTAREA_HEIGHT = 20;
    const textareaRef = useRef(null);
    const chatRef = useRef()
    const lastChatElement = useRef()


    // Чтобы сразу можно было вводить сообщения
    useEffect(() => {
        textareaRef.current.focus()
    }, [])

    useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textareaRef.current.style.height = "inherit";
        // Set height
        const heightOfTextarea = Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
        )
        textareaRef.current.style.height = `${heightOfTextarea}px`;
        // 20px - отступы, 5px - margin-top у textarea, 1px - border-top
        chatRef.current.style.height = `calc(100% - 20px - 5px - 1px - ${heightOfTextarea}px)`
    }, [newMessageValue]);

    const handleKeyPressTextarea = useCallback((event) => {
        if (event.key === 'Enter' && !event.shiftKey){
            sendChatMessage()
            event.preventDefault()
        } else if (event.key === 'Enter' && !event.shiftKey) {
            setNewMessageValue(prev => prev + '\n')
        }
    }, [sendChatMessage, setNewMessageValue])

    const [popupVacanciesSelectorOpenedFlag, setPopupVacanciesSelectorOpenedFlag] = useState(false)


    return <div className={'chatpage-main-block'}>
        {(globalNotificationMessage) && <div className={'global-error-main'}>
            <div className={'global-error-block'}>
                <InlineNotification text={globalNotificationMessage} type={globalNotificationType} hideFlag={false}/>
                <button className={'global-error-reload-button'} onClick={() => window.location.reload()}>
                    <ReloadIcon/>
                </button>
            </div>
        </div>}
        {(Bitrix24AuthorizedFlag === true && HHAuthorizedFlag === false) && <div className={'global-error-main'}>
            <div className={'global-error-block'}>
                <button className={'global-error-reload-button'} onClick={auth}>
                    Авторизоваться на hh.ru
                </button>
            </div>
        </div>
        }
        <Loading isLoading={isLoading}/>
        <Popup openedFlag={popupVacanciesSelectorOpenedFlag} canBeClosedFlag={false}>
            <div className={'vacancies-selector-header'}>Выберите вакансию, по которой хотите вести общение</div>
            <div className={'vacancy-list'}>
                {(!!availableVacancies?.length) ? availableVacancies.map(el => <div className={'vacancy'} onClick={() => selectVacancy(el.id)}>
                    <div className={'vacancy-name'}>
                        {el.name}
                    </div>
                    <div className={'vacancy-city'}>
                        {el.area.name}
                    </div>
                </div>) : <></>}
                {(!availableVacancies?.length) && <div className={'vacancies-not-found'}>
                Доступных вакансий не найдено
                </div>}
            </div>
        </Popup>
        <div className={'chat-block'}>
            <div className={'chat-body'} ref={chatRef}>
                {negotiationFoundFlag === false &&
                    <div className={'chat-center-container'}>
                        <div className={'chat-clear-message'}>
                            <div className={'negotiation-not-found-text'}>Отклик или приглашение не найдены</div>
                            {(!!selectedVacancy?.negotiations_actions?.length) && <button className={'negotiation-send-button'}
                                     onClick={sendNegotiation}>Пригласить</button>}
                        </div>
                    </div>}
                {(chatMessages.length === 0 && negotiationFoundFlag) &&
                    <div className={'chat-center-container'}>
                        <div className={'chat-clear-message'}>
                            Сообщений не найдено
                        </div>
                    </div>
                }
                {chatMessages.map(message =>
                    <div className={'chat-row'} key={message.id}>
                        <div className={`message ${message.author.participant_type === 'applicant' ? 'message-left' : 'message-right' }`}>
                            <div className={`message-text ${(!message.text) ? "message-not-allowed" : ""}`}>{message.text ? message.text : 'Тип сообщения не поддерживается'}</div>
                            <div className={'message-info'}>
                                {(message.author.participant_type !== 'applicant') &&
                                    <div className={`message-read-status ${message.viewed_by_opponent ? 'status-read' : ''}`}>
                                        {(!message?.is_pending) ?
                                            <DoubleTickIndicator/>
                                            : <ClockSVG/>
                                        }
                                    </div>
                                }
                                <div className={'message-write-time'}>
                                    {moment(message.created_at).format('DD.MM HH:mm')}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={lastChatElement} className={'last-chat-element'}></div>
                {!!updateChatErrorNotificationMessage &&
                    <InlineNotification
                        className={'local-chat-error'}
                        text={updateChatErrorNotificationMessage}
                        type={'error'}
                        hideFlag={false}
                    />
                }
                {!!sendMessageErrorNotificationMessage &&
                    <InlineNotification
                        className={'local-chat-error'}
                        text={sendMessageErrorNotificationMessage}
                        type={'error'}
                        hideFlag={false}
                    />
                }
            </div>
            <div className={'chat-input'}>
                <textarea
                    disabled={negotiationFoundFlag === false || negotiationFoundFlag === undefined}
                    contentEditable={true}
                    placeholder={(negotiationFoundFlag !== false && negotiationFoundFlag !== undefined) ? 'Написать сообщение...' : 'Чат с соискателем не доступен'} // Сюда же можно передать текст при отключенном чате
                    rows={1}
                    onChange={(event) => setNewMessageValue(event.target.value)}
                    ref={textareaRef}
                    style={{
                        minHeight: MIN_TEXTAREA_HEIGHT,
                        resize: "none"
                    }}
                    onKeyDown={handleKeyPressTextarea}
                    value={newMessageValue}

                />
                <button disabled={!newMessageValue.trim()} onClick={sendChatMessage}>
                    <AirplaneIcon/>
                </button>
            </div>
        </div>
        <div className={'profile-info-block'}>
            <div className={'profile-main-info'}>
                <div className={'profile-photo'}>
                    <img className={'profile-photo-img'} src={
                        resume?.photo?.medium ? resume?.photo?.medium : 'https://icon-library.com/images/flat-person-icon/flat-person-icon-23.jpg'
                    }/>
                </div>
                <div className={'profile-fio-and-resume'}>
                    <div className={'profile-fio'}>
                        {resume?.first_name ?
                            `${resume.last_name} ${resume.first_name}${resume?.middle_name ? " " + resume.middle_name : ""}`
                            : 'Соискатель'
                        }
                    </div>
                    <div className={'profile-resume'}>
                        {resume?.title ? resume.title : 'Должность не указана'}
                    </div>
                </div>
            </div>
            <div className={'info-blocks'}>
                {resumeInfoList.map(el =>
                    <div className={'info-block'}>
                        <div className={'info-block-icon'}>{el.icon}</div>
                        <div className={'info-block-data'}>
                            <div className={'info-block-value'}>
                                {el.value}
                            </div>
                            <div className={'info-block-name'}>
                                {el.name}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={'actions-blocks'}>
                <button className={'select-vacancy-button'} onClick={changeVacancy}>
                    <div className={'action-block-icon'}><SwapIcon/></div>
                    <div className={'action-block-name'}>Сменить вакансию</div>
                </button>
                <button className={'open-hh-button'} onClick={() => window.open(
                    `https://hh.ru/resume/${resume?.id}`,'_blank'
                )}>
                    <div className={'action-block-icon'}><HeadhunterIcon/></div>
                    <div className={'action-block-name'}>Открыть на Headhunter</div>
                </button>
            </div>
        </div>
    </div>
}
